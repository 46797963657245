<template>
  <footer class="text-center text-sm-left d-none d-sm-block" :class="{ 'dark-mode': isDarkMode }">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 mb-2 mb-md-0 d-flex justify-content-center justify-content-md-between">
          <div class="row w-100">
            <div class="col-4 mb-2 mb-md-0">
              <i :class="{ 'text-primary': $route.path !== '/' }" class="fab fa-facebook mr-3" aria-hidden="true"></i>
              <i :class="{ 'text-primary': $route.path !== '/' }" class="fab fa-twitter mr-3" aria-hidden="true"></i>
              <i :class="{ 'text-primary': $route.path !== '/' }" class="fab fa-instagram mr-3" aria-hidden="true"></i>
              <i :class="{ 'text-primary': $route.path !== 'https://www.linkedin.com/company/karisoft-solutions' }" class="fab fa-linkedin" aria-hidden="true"></i>
            </div>

            <div class="col-8 col-md-6">
              <p :class="{ 'text-primary': $route.path !== '/' }">Copyright Karisoft Solution LTD.
                {{ new Date().getFullYear() }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 text-center text-sm-right">
          <p>
            <span :class="{ 'text-primary': $route.path !== '/' }">Terms and Condition</span>
            <span><i :class="{ 'text-primary': $route.path !== '/' }" class="fas fa-circle mx-2"></i></span>
            <span :class="{ 'text-primary': $route.path !== '/' }">Privacy Policy</span>
            <span><i class="fas fa-circle mx-2"></i></span>
            <span :class="{ 'text-primary': $route.path !== '/' }">Cookie Policy</span>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isDarkMode: false
    }
  },
  mounted() {
    // Add event listener to detect dark mode preference
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      this.isDarkMode = event.matches;
    });
  }
}
</script>

<style scoped>
.fa, .fas {
  font-size: 1.5em;
}

.dark-mode footer {
  background-color: #333;
  color: white;
}

.dark-mode .text-primary {
  color: white;
}

.dark-mode p {
  font-size: 11px;
}

footer {
  position: fixed;
  color: white;
  z-index: 999;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  padding: 20px 0;
}

p {
  margin-bottom: 0;
  font-size: 13.5px;
}

@media (max-width: 600px) {
  footer {
    position: relative;
    font-size: 12px;
  }
}
</style>
