<template>
  <div>
    <section id="banner" class="position-relative">
      <div class="banner-overlay">
        <div class="banner-text">
          <div class="logo animate__animated animate__rotateIn animate__slow">
            <b-img src="/img/karilogo.png" fluid></b-img>
          </div>

          <h1 class="animate__animated animate__fadeIn animate__slow">
            Tailored software solutions for your unique business needs
          </h1>
          <p class="mb-2 lead animate__animated animate__fadeIn animate__slow"> We build for <typewriter  :speed="200"     :full-erase="true"     :interval="3000"     :words='["E-commerce","Healthcare","Logistics","Fintech","Retail","Media & Entertainment","Government","Education","Blockchain"]' style="color: red
          ;"></typewriter></p>
          
          <!-- <p class="mb-5 animate__animated animate__fadeIn animate__slow">We offer full-cycle custom application development to bring your complex software vision to life, allowing you to prioritize delivering a unique user experience.</p> -->
          
          <p class="mb-5 animate__animated animate__fadeIn animate__slow">
            transforming your Ideas to solutions
          </p>
          <router-link to="/about">
            <b-button variant="danger" size="lg"
              >Click to learn more about us</b-button
            ></router-link
          >
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
#banner {
  position: relative;
  height: 100vh;
  background-image: url("/img/isometric-exo-home.gif");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 57 158 / 81%);
  display: flex;
  justify-content: center;
  align-items: center;
}
span.typewriter-msg {
    color: red !important;
}
span.typewriter-cursor{
  color: red;
}
.banner-text {
  text-align: center;
  color: #fff;
  text-transform: capitalize;
}
.logo {
  margin: 0 auto 50px;
}
h1{
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
}
p.lead{
  font-weight: 600;
}

p {
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}
@media (max-width: 600px) {
  #banner {
    padding: 20px;
  }
  .logo {
    width: 200px;
  }
  h1{
    font-size: 30px;
    line-height: 1.4;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  h3 {
    font-size: 20px;
    line-height: 1.4;
  }
  p {
    font-size: 15px;
    line-height: 1.4;
  }
}
</style>

<script>
export default {
  methods: {
    scrollDown() {
      window.scrollTo(0, window.innerHeight);
    },
  },
};
</script>
